.dot {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  height: 85vh;
  background-color: #1d212a;
  border-radius: 10px;
}
.dotD {
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
}

.spy {
  margin-bottom: 120px;
}

/*devices (phones) */
@media only screen and (max-width: 575.98px) {
  .dot {
    height: 100%;
    margin-bottom: 20px;
    position: relative !important;
  }
}
