.hospital-table-container {
  width: 90%;
  margin: 0 auto;
  padding-top: 3rem;
}

.hospital-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2rem;
}

.hospital-table th,
.hospital-table td {
  border-bottom: 1px solid #555252;
  padding: 8px;
  text-align: left;
}

.hospital-table th {
  background-color: #888;
  color: #181717;
  text-align: center;
}

.hospital-table td {
  color: white;
  text-align: center;
}

.hospital-table tbody tr:hover {
  background-color: rgb(48, 47, 47);
}

.hospital-table td button {
  color: #007bff;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
}

.hospital-table td button:hover {
  text-decoration: underline;
}

.modified {
  color: #fff;
}
@media only screen and (max-width: 575.98px) {
  .modified {
    height: 98vh;
    width: 98vw;
    font-weight: normal;
    font-size: 10px;
  }
}
