button {
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  text-align: left;
}

.toggle-button {
  position: fixed;
  top: 25px;
  left: 30px;
  z-index: 1050;
  color: #e1ecff;
}

.sidebar {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%; /* Take full width in its column */
  padding: 0 16px;
  background: #1d212a;
  border-right: 1px solid #2e303e;
  transition: transform 0.3s ease;
  height: 100vh;
  overflow-x: auto;
  flex-shrink: 0;
  padding-top: 20px;
  padding-left: 20px;
  
  
}
/* Sidebar button styles */
.sidebar button {
  position: relative;
  display: flex;
  gap: 16px;
  align-items: center;
  height: 50px;
  width: 100%;
  border-radius: 6px;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  padding: 0 16px;
  color: #e1ecff;
  background: transparent;
  transition: background 0.3s, color 0.3s;
  cursor: pointer;
  text-align: left;
}

/* Highlight on hover */
.sidebar button:hover {
  background: #3a3f4a; /* Light color for hover effect */
  color: #ffffff;
}

/* Highlight when active */
.sidebar button.active {
  background: #5b5b5b; /* Color when active */
  color: #ffffff;
}

/* Rotate icon when active */
.sidebar button.active > span:nth-child(3) {
  rotate: -180deg;
}


.content {
  background-color: #242b38;
  height: 100vh;
  transition: margin-left 0.4s ease;
 
}

.sidebar.open {
  transform: translateX(0);
}

.shift-right {
  margin-left: 0;
}

.shift-left {
  margin-left: 0; /* Reset margin when sidebar is hidden */
}

.pos {
  position: sticky;
  top: 0;
  overflow: visible !important;
  width: 100%;
}

.main-content {
  padding: 5px;
 
}
.sidebar-header{
  background-color: #1d212a;
}
.padding-adjust-sideBar{
  padding-right: 0px;

}
.padding-adjust-main-content{
  padding-left: 0px;

}
.nav-padding-handling{
  padding-left: 0px !important;
  padding-right: 0px !important;
  position: fixed;
}

@media only screen and (max-width: 768px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 250px;
    height: 100vh;
    z-index: 1000;
    overflow-y: auto;
  }
}
