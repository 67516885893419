body {
  font-family: "Raleway";
  font-size: 16px;
}

.footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
}

.copyright-part {
  padding-top: 10px;
  text-align: center;
}

.icon-text {
  margin-left: 10px; /* Adjust the margin to your preference */
}

.line {
  list-style: none;
  margin-right: 10px;
}

.contact-list {
  margin: 0px;
  padding: 0px;
}

.number {
  font-family: montserrat;
  font-size: 16px;
  font-weight: 100px;
}
