.medical-report-form-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  color: #faebd7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-sub-div {
  width: 100%;
  border-radius: 9px;
  box-shadow: 0 4px 8px rgba(103, 104, 103, 0.4);
  padding: 20px;
  background-color: #1d212a;
}

.medical-report-form {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

.form-group {
  margin-bottom: 20px;
}

input[type="text"]::placeholder,
input[type="number"]::placeholder {
  color: #bbc1c9 !important;
}

input[type="file"],
.select-one,
.placeholder-color {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #1d212a;
  color: #bbc1c9 !important;
}

.submit-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  width: 180px;
}

.submit-button:hover {
  background-color: #0056b3;
}

h2 {
  text-align: center;
  font-size: 24px;
}

/* Medium screens (tablets) */
@media only screen and (max-width: 768px) {
  .medical-report-form-container {
    padding: 15px;
    margin: 30px;
    border-radius: 10px;
    background-color: rgb(103, 114, 125);
  }

  .form-sub-div {
    padding: 15px;
  }

  input[type="text"],
  input[type="number"],
  input[type="select-one"],
  input[type="file"],
  .select-one {
    padding: 8px;
    font-size: 14px;
  }

  .submit-button {
    padding: 10px;
    font-size: 16px;
  }

  h2 {
    font-size: 20px;
    color: aliceblue !important;
  }
}
/* Extra small screens (small mobile devices) */
@media only screen and (max-width: 425px) {
  .medical-report-form-container {
    padding: 10px;
    margin: 10px;
    margin-top: 50px;
  }

  .form-sub-div {
    padding: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  input[type="text"],
  input[type="number"],
  input[type="select-one"],
  input[type="file"],
  .select-one {
    padding: 6px;
    font-size: 12px;
  }

  .submit-button {
    padding: 8px;
    font-size: 14px;
  }

  h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }
}
/* support page styles */
.support-placeholder-color::placeholder {
  color: black !important;
}
