.tech2 {
  font-size: 100px;
  font-weight: 600;
  text-align: center;
}

.login-page {
  background: #9d9ef7;
  background: -webkit-linear-gradient(110deg, #9d9ef7 0%, #56f666 100%);
  background: linear-gradient(110deg, #9d9ef7 0%, #56f666 100%);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
}

.login-form {
  background-color: #2a2e38;
  color: #ffff;
  padding: 40px;
  height: 400px;
  width: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgb(92, 92, 92);
}

.user-animation {
  animation: userMove 2s infinite alternate;
}

.user-icon {
  width: auto;
  height: 350px;
  margin: auto;
}

.password-group {
  position: relative;
}

.password-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
  color: rgb(168, 159, 159);
  font-size: 24px;
}

@keyframes userMove {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(20px);
  }
}

/* Extra small devices (phones) */
@media only screen and (max-width: 768px) {
  .user-animation {
    margin-bottom: 70px !important;
    display: none;
  }

  .login-page {
    height: 100% !important;
  }
  .tech2 {
    padding: top -30px;
    padding-bottom: 10px;
  }
}

/* devices (tablets) */
@media only screen and (max-width: 767.98px) {
  .login-page {
    height: 100% !important;
  }
}

/* devices (desktops) */
@media only screen and (min-width: 768px) {
  .login-page {
    height: 100% !important;
  }
}
