.form-control {
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #2a2e38;
  color: #bbc1c9;
}
 
.form-select {
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #2a2e38;
  color: #bbc1c9;
}
 
.Form-header {
  border-radius: 30px;
  text-align: center;
  position: fixed;
  width: 100%;
  top: 0;
}
 
.form-main {
  box-shadow: 0 4px 8px rgba(103, 104, 103, 0.4);
  padding: 30px;
  margin: 30px auto;
  background-color: #1d212a;
  border-radius: 8px;
  max-width: 970px;
  width: calc(100% - 60px);
}
 
.heading {
  text-align: center;
  margin-bottom: 10px;
  color: white;
}
 
.div-1 {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
 
.formc::placeholder {
  color: #bbc1c9 !important;
}
 
.form-label {
  color: antiquewhite;
}
 
.Form {
  box-shadow: 0 4px 8px rgba(103, 104, 103, 0.4);
  border-radius: 8px;
  padding: 20px;
  background-color: #1d212a;
}
 
.button {
  background-color: #5b5b5b;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 2px;
  cursor: pointer;
  border-radius: 8px;
}
.error-message {
  color: red !important;
  font-size: 0.675rem; /* Adjust font size as needed */
  margin-top: 0.25rem; /* Optional: Adjust spacing */
}

 
@media (max-width: 600px) {
  .form-main {
    padding: 20px;
    margin: 20px 10px;
    width: calc(100% - 40px);
  }
 
  
  .Form-header {
    padding: 10px;
    font-size: 18px;
  }
 
  .heading {
    font-size: 20px;
  }
 
  .button {
    width: 100%;
    padding: 15px;
    margin: 5px 0;
  }
}