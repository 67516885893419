
.tech2 {
  font-size: 100px;
  font-weight: 600;
  text-align: center;
}
 
.signup-page {
  background: #9d9ef7;
  background: -webkit-linear-gradient(110deg, #9d9ef7 0%, #56f666 100%);
  background: linear-gradient(110deg, #9d9ef7 0%, #56f666 100%);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
}
 
.signup-form {
  background-color: #2a2e38;
  color: #fff;
  padding: 20px;
  height: auto; /* Increased height to accommodate additional form fields */
  width: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgb(92, 92, 92);
}
 
.user-animation {
  animation: userMove 2s infinite alternate;
}
 
.password-group {
  position: relative;
}
.password-input {
  position: relative;
}
 
.password-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
  color:rgb(168, 159, 159);
  font-size: 24px;
}
 
 
 
.user-icon {
  width: auto;
  height: 450px;
  margin: auto;
}
 
.errorMessage{
  color: rgb(250, 104, 104);
  font-size: 14px;
}
 
@keyframes userMove {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(20px);
  }
}
 
/*devices (phones) */
@media only screen and (max-width: 575.98px) {
  .user-animation {
    margin-bottom: 70px !important;
  }
  .signup-page {
    height: 100% !important;
  }
  .user-icon {
    width: 100% !important;
  }
}
 
/*devices (tablets) */
@media only screen and (min-width: 576px) {
  .signup-page {
    height: 100% !important;
  }
}
 
/*devices (desktops) */
@media only screen and (min-width: 768px) {
  .signup-page {
    height: 100% !important;
  }
}
 