.Toastify__toast-theme--light {
    /* background: var(--mirror-gradient) !important; */
    background: #1d212a;
    color: var(--toastify-text-color-light) !important;
    font-family: 'Raleway', sans-serif !important;
    font-size : 20px !important;
    color :#fff !important;
  }

  .Toastify__close-button {
    color: #fff;
  }