.head{
    color:white;
}

.appointment-buttons{
    color:white;
}

.back-button-alignment {
    text-align: start !important;
    /* background-color:#1d212a; */
   
}
.btn{
    color:aliceblue !important;
}


.back-button{
    position:sticky !important;
}
/* .img-container{
    background-image: url("../images/");
} */