.support{
    width: auto;
}

.cardBar {
    /* border: 1px solid rgb(221, 207, 207); */
    border-radius: 8px;
    background-color: #121d2a;
    box-shadow: 0px 3px 0px 2px  rgb(70, 69, 68);
    padding: 15px;
}