/* Add Hospital Button Background Styles */
.css-wsew38 {
  background-color: #1d212a !important;
}
::-webkit-scrollbar {
  height: 5px;
}
.css-zrlv9q{
  background-color: #1d212a !important;
}

/* Talbel Last Row Background Color Means Rows Per Page   */
.css-1joj1yp {
  background-color: #1d212a !important;
}

/* Table Data Rows Styles */
.MuiTableCell-root {
  background-color: #1d212a !important;
  padding-left: 15px !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  /* color: rgb(216, 233, 149) !important; */
  font-size: 16px !important;
  
}

/* Table Head Data Styles */
.css-lapokc {
  font-size: medium;
  font-size: 18px !important;
  color: #fff !important;
}

.MuiTableCell{
  width: 5px !important;
}

.css-jewjre-MuiPaper-root-MuiDialog-paper{
  background-color: #1d212a !important;
}

.MuiTableBody-root.css-29nbga-MuiTableBody-root {
  overflow-y: auto;
}


/* .css-11786dc-MuiPaper-root {
  width:100% !important;
  overflow: hidden !important;
} */

/* Styles For Mobile Devises */
@media only screen and (max-width: 575.98px) {
  .TableC {
    width: 100vw;
    min-height: 100vh;
  }
}
@media only screen and (min-width:768px){
  .TableC{
    
    min-height:100%;
    font-size: 14px !important;

  }
}
@media only screen and (min-width: 768px) {
  .TableC {
    min-height: 100%;
    font-size: 14px !important;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden; /* Allow horizontal scrolling */
    display: block;
    white-space: nowrap; /* Prevent table from wrapping */
  }
}


