.message-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.message-input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.send-notification-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.send-notification-button:hover {
  background-color: #0056b3;
}

.notification-container {
  height: auto !important;
  background-color: #242b38;
}

.note {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-shadow{
  box-shadow: 0 4px 8px rgba(103, 104, 103, 0.4);
}

/*devices (phones) */
@media only screen and (max-width: 575.98px) {
  .note {
    width: 100%;
    height: 100%;
    font-size: 20px;
  }
  .message-input {
    width: 100%;
  }
}

@media only screen and (max-width: 1023.98px) {
  .note {
    width: auto;
    height: auto;
  }

  .note {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/*devices (phones) */
@media only screen and (max-width: 575.98px) {
  .note {
    width: 100%;
    height: 100%;
    font-size: 20px;
  }
  .message-input {
    width: 100%;
  }

  .titleC {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1023.98px) {
  .note {
    width: auto;
    height: auto;
  }
  .message-input {
    width: 100%;
  }
}
